<template>
  <v-card
    flat
    light
    class="media-upload"
    :class="{ highlight: controller.highlight }"
    @drop.prevent="upload($event, true)"
    @dragover.prevent="toggleHighlight(true)"
    @dragenter="toggleHighlight(true)"
    @dragleave="toggleHighlight(false)"
  >
    <v-btn
      v-if="closeable"
      fab
      absolute
      top
      right
      small
      icon
      color="grey lighten-1"
      class="btn-close mt-16 mr-n1"
      @click.stop="close"
    >
      <v-icon>
        {{ icons.close }}
      </v-icon>
    </v-btn>
  
    <v-card-text
      class="media-upload-content d-flex flex-column justify-center align-center text-center px-8 pb-8r my-5 fill-height"
      @click.stop="select"
    >
      <div class="icon-play mb-3">
        <v-icon color="white" class="icon">
          {{ icons.videoCheck }}
        </v-icon>
      </div>

      <h4 class="title text-h5 text-overline font-weight-medium my-2">
        {{ title }}
      </h4>

      <v-alert
        class="mb-0 rounded-lg"
        max-width="320"
        :color=" 
          controller.success === null 
            ? 'transparent'
            : controller.success === false
              ? 'warning'
              : 'success'
        "
      >
        <media-player
          :url="
            media == null
              ? test && controller.url != null
                ? controller.url
                : null
              : media.url
          "
          :title="media == null ? 'ENVIAR MÍDIA (MP4/JPG)' : media.title"
          rounded
          class="upload-player mb-4"
        />

        <v-expand-transition mode="in-out">
          <p
            v-if="test && validation.loading"
            class="text-body-2 mb-0 mt-5 white--text"
          >
            <v-progress-circular
              indeterminate 
              size="24" 
              class="mr-2"
            />
            Validando mídia... 
          </p>

          <div v-else-if="controller.success === false || (test && validation.success === false)">
            <p
              v-for="(error, e) in controller.errors"
              :key="'upload-error-' + e"
              class="text-body-2 my-1"
            >
              <v-icon left>{{ icons.alert }}</v-icon>
              <span v-html="error" />
            </p>
          </div>
      
          <div 
            class="d-flex justify-center" 
            v-else-if="test ? validation.success === true : controller.success === true"
          >
            <v-icon class="mr-2">{{ icons.guide }}</v-icon>
            <p class="text-subtitle-1 mb-0">
              Mídia validada!
            </p>
          </div>
  
          <p
            v-else-if="controller.errors.length == 0"
            class="text-body-2 mb-0 mt-4 white--text"
          >
            <em>Clique aqui</em> para selecionar o arquivo <br />
            ou arraste-o para cá.
          </p>
        </v-expand-transition>
      </v-alert>

      <input
        type="file"
        ref="upload-field"
        class="d-none"
        @input="upload($event, false)"
      />
      <div class="text-center">
        <v-menu offset-x right>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              v-bind="attrs"
              v-on="on"
              outlined
              color="white"
              class="text-overline mt-4"
            >
              Ajuda
              <v-icon color="white" right small>{{ icons.help }}</v-icon>
            </v-btn>
          </template>
          <v-list class="formats py-0">
            <v-menu
              v-for="format in formats"
              :key="`format-${format.icon}`"
              transition="slide-x-transition"
              offset-x
              right
              nudge-top="12"
              open-on-hover
              open-delay="250"
              close-delay="250"
              :close-on-content-click="false"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-list-item v-bind="attrs" v-on="on" @click.stop>
                  <v-list-item-icon class="mr-4">
                    <v-icon dense>
                      {{ icons[format.icon] }}
                    </v-icon>
                  </v-list-item-icon>
                  <v-list-item-content>
                    <v-list-item-title
                      class="text-overline text-caption grey--text"
                    >{{ format.title }}</v-list-item-title>
                  </v-list-item-content>
                  <v-list-item-icon>
                    <v-icon dense color="grey lighten-1">
                      {{ icons.info }}
                    </v-icon>
                  </v-list-item-icon>
                </v-list-item>
              </template>
              <v-card>
                <v-card-text class="px-6">
                  <p class="text-overline">
                    Especificações Técnicas:
                  </p>
                  <p v-html="format.text" class="text text-body-2" />
                </v-card-text>
              </v-card>
            </v-menu>

            <v-divider />

            <v-list-item @click="downloadGuide">
              <v-list-item-icon class="mr-4">
                <v-icon dense color="primary">
                  {{ icons.guide }}
                </v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title
                  class="text-overline text-caption primary--text"
                >
                  Baixe o guia de criação
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </v-menu>
      </div>
    </v-card-text>

    <div class="media-tester">
      <video ref="video" />
      <img ref="image" />
    </div>
  </v-card>
</template>

<style>

.media-upload {
  background-color: var(--creative-pink-400) !important;
  border-radius: 100% !important;
  width: 540px;
  height: 540px;
}

.icon-play {
  display: flex;
  align-items: center;
  justify-content: center;
  border:1px solid white;
  border-radius: 100%;
  margin-bottom: 10px;
  width: 60px;
  height: 60px;
}

.media-upload-content {
  cursor: pointer;
}
.media-upload-content h4 {
  color: white;
}

.upload-player .media-placeholder {
  border: 2px dashed white;
}

.panels {
  width: 320px;
  height: 33px;
}

.row {
  flex: 0;
}

.media-upload header {
  cursor: pointer;
}

.media-upload em {
  font-style: normal;
  border-bottom: 1px dotted;
}

.media-upload .formats .text {
  line-height: 1.5;
}

.media-upload .media-tester {
  position: fixed;
  left: 9999999px;
  visibility: hidden;
}
</style>

<script>
import {
  mdiVideoBox,
  mdiFileJpgBox,
  mdiFileCheck,
  mdiCards,
  mdiClose,
  mdiArrowLeft,
  mdiAlert,
  mdiInformationOutline,
  mdiHelpCircleOutline,
  mdiMovieCheckOutline
} from "@mdi/js";

export default {
  props: {
    closeable: {
      type: Boolean,
      default: true
    },
    test: {
      type: Boolean,
      default: false
    },
    media: {
      type: Object,
      default: () => null
    },
    validation: {
      type: Object,
      default: () => {
        return {
          success: null,
          message: null,
          loading: false
        }
      }
    }
  },

  data: () => ({
    icons: {
      video: mdiVideoBox,
      image: mdiFileJpgBox,
      guide: mdiFileCheck,
      gallery: mdiCards,
      close: mdiClose,
      back: mdiArrowLeft,
      alert: mdiAlert,
      info: mdiInformationOutline,
      help: mdiHelpCircleOutline,
      videoCheck: mdiMovieCheckOutline
    },
    controller: {
      url: null,
      highlight: false,
      allowed: ['image/jpeg', 'video/mp4', 'image/svg+xml', 'text/plain.dsp', '.dsp'],
      max: 1000000,
      errors: [],
      success: null
    },
    formats: [
      {
        icon: "video",
        title: "Mídia vídeo mp4",
        text:
          "Largura: <b>288px</b><br>Altura: <b>96px</b><br>Duração: <b>8 segundos</b><br>Framerate: <b>30fps</b><br><b>Sem áudio</b><br>Resolução: <b>72dpi</b>"
      },
      {
        icon: "image",
        title: "Mídia estática jpg",
        text:
          "Largura: <b>288px</b><br>Altura: <b>96px</b><br>Resolução: <b>72dpi</b>"
      }
    ]
  }),

  computed: {
    title () {
      const test = this.test;
      const media = this.media;
      const text = test
        ? "Validador de mídia"
        : media == null
        ? null 
        : "Alterar Mídia";
      return text;
    },

  },

  watch: {
    validation: {
      deep: true, 
      handler (validation) { 
        // if(validation.success != null) {
        //   this.controller.success = validation.success
        //   this.toggleAlert(!validation.success, [validation.message])
        // }
        this.controller.success = validation.success
        this.controller.errors = [validation.message]

      }
    }
  },

  methods: {
    select (e) {
      console.log("select file");
      this.$refs["upload-field"].click();
    },
    upload (event, dropped) {
      const file = dropped
        ? event.dataTransfer.files[0]
        : event.target.files[0];
      console.log("upload file", file);

      setTimeout(this.toggleHighlight(false), 250);
      this.toggleAlert(false);

      const $ = this;
      if (this.validType(file)) {
        var reader = new FileReader();
        reader.onload = e => {
          console.log("loaded file", e.target);
          if (file.name.indexOf("dsp")>=0) {
            $.validate(file, { src: e.target.result });
          }else if (file.type.indexOf("video")>=0) {
            $.$refs["video"].addEventListener("loadeddata", meta => {
              $.validate(file, meta.target);
            });
            $.$refs["video"].src = e.target.result;
          }else{
            const test = new Image();
            test.addEventListener("load", meta => {
              $.validate(file, meta.target);
            });
            test.src = e.target.result;
          }
        };
        reader.readAsDataURL(file);
      } else {
        this.validate(file);
      }
    },

    validate (file, metadata) {
      let messages = [];
      if (file.size > this.controller.max) {
        messages.push("A mídia deve ter <b>até 1MB</b> de tamanho");
      }
      if (!this.validType(file)) {
        messages.push("Os formatos aceitos são <b>JPG</b> ou <b>MP4</b>");
      }else if (file.name.indexOf("dsp")<0) {
        let { width, height } = metadata;
        width = width == 0 ? metadata.clientWidth : width;
        height = height == 0 ? metadata.clientHeight : height;
        if (width < 288 || width / height != 3) {
          messages.push("A mídia deve ter <b>288px por 96px</b>");
        }
        // if (file.type.indexOf('video')>=0) {
        //   if (metadata.mozHasAudio ||
        //     Boolean(metadata.webkitAudioDecodedByteCount) ||
        //     Boolean(metadata.audioTracks && metadata.audioTracks.length)) {
        //     messages.push('Remova a faixa de áudio, por favor')
        //   }
        // }
      }
      const success = messages.length == 0;
      if (success) {
        if (this.test) {
          this.controller.url = metadata.src;
          this.$emit('validate', metadata.src)
        } else {
          const url = metadata.src;
          const filename = file.name;
          const format = _.toUpper(_.indexOf(file.name, '.')>=0 ? _.last(_.split(file.name, '.')) : _.split(file.type, '/')[1]);
          this.update({ url, filename, format });
        }
      } else {
        this.toggleAlert(!success, messages);
      }
      this.controller.success = success&&this.test ? null : success
      return this.controller.success;
    },

    validType (file) {
      return _.some(this.controller.allowed, allowed => {
        const [ type, extension=null ] = _.split(allowed, '.');
        return type==file.type&&(extension==null||(_.indexOf(file.name, '.')>=0&&_.last(_.split(file.name, '.'))==extension));
      })
    },

    toggleAlert (b, messages) {
      if (b) {
        this.controller.errors = messages;
      } else {
        this.controller.success = null;
        this.controller.errors = [];
      }
    },

    toggleHighlight (b) {
      this.controller.highlight = b;
      this.$emit("toggle", true);
    },

    downloadGuide () {
      console.log("download guide");
      window.open("/guia-criacao-mobees.pdf", "_blank");
    },

    update (media) {
      this.$emit("update", media);
      if (!this.test) setTimeout(() => this.$emit("toggle", false), 500);
    },

    close () {
      this.$emit("toggle", false);
    },

    handleException (error) {
      console.log(error);
    }
  },

  components: {
    MediaPlayer: () => import("@/components/campaigns/MediaPlayer")
  }
};
</script>
